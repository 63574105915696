import type { ServerResponseCommonType } from '~/types/commons';

import type { PaginationQueryType } from '~/types/commons';
import type { OrderResponce, CreateOrderRequest, Order } from './types/orderTypes';


export function useOrders() {
  const { $api } = useNuxtApp();  

  const getOrders = async (options: PaginationQueryType = {}) => {
    try {
      const res = await $api<OrderResponce>('/order/all', {
        method: 'GET',
        params: options,
      });
      console.log(res)
      if (res && res.orders.data) {       
		return res.orders;
      }
      return null;
    } catch (error) {
      console.error('Error fetching orders:', error);
      return null;
    }
  }

  const createOrder = async (options:CreateOrderRequest): Promise<any> => {
    try {
      const res = await $api<{ order: Order }>('/order/create', {
        method: 'POST',
        body: options
      });
      return res

    } catch (error) {
      console.error('Error creating order:', error);
      return null;
    }
  }

  const sendContent = async (id:number)=>{
	try {
		const res = await $api(`/order/send-content/${id}`, {
		  method: 'GET',
		});
		return res
  
	  } catch (error) {
		console.error('Error creating order:', error);
		return null;
	  }
  }
  
  const downloadContent = async (id: number) => {
    try {
      const res = await $api<Blob>(`/order/download-content/${id}`, {
        method: 'GET',
        responseType: 'blob',
      });
      return res
    } catch (error) {
      console.error('Error creating order:', error);
      return null;
    }
  }

  const orderApprove = async (id: number) => {
    try {
      const res = await $api(`/order/approve/${id}`, {
        method: 'GET',
      });
      return res
    } catch (error) {
      console.error('Error approve order:', error);
      return null;
    }
  }

  const orderReject = async (body: { orderId: number, message: string }) => {
    try {
      const res = await $api(`/order/reject`, {
        method: 'POST',
        body
      });
      return res
    } catch (error) {
      console.error('Error reject order:', error);
      return null;
    }
  }

  return {
    getOrders,
    createOrder,
    sendContent,
    downloadContent,
    orderApprove,
    orderReject
  }
}
